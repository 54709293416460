import React, { useState } from "react";
import { ModuleWrapper } from "../moduleWrapper";
import cx from "classnames";
import { motion, AnimatePresence } from "framer-motion";

import ChevronPrev from "@svg/chevron-prev.svg";

const variants = {
  inactive: {
    rotate: 270,
  },
  active: {
    rotate: 90,
    transition: {
      duration: 0.5,
    },
  },
};

const AccordionItem = ({
  title,
  rowOneTitle,
  rowTwoTitle,
  statusTitle,
  rows,
  setActive,
  active,
  index,
}) => {
  const isActive = active === index;
  const handleClick = () => (!isActive ? setActive(index) : setActive(null));

  console.log(active);

  return (
    <div
      className={cx("  py-9 px-11 transition-all duration-700 ease-in-out", {
        "bg-white rounded-3xl": isActive,
        "border-t border-red": index !== 0 && index !== active + 1 && !isActive,
      })}
    >
      <div
        className="flex justify-between  cursor-pointer  "
        onClick={handleClick}
      >
        <div>
          <motion.h2 className="text-4xl text-red">{title}</motion.h2>
        </div>

        <motion.button
          variants={variants}
          initial="inactive"
          animate={isActive ? "active" : "inactive"}
          className="transition-all ease-in-out duration-300  w-12 h-12 rounded-full cursor-pointer touch-manipulation bg-red bg-opacity-10  "
        >
          <ChevronPrev className="m-auto" />
        </motion.button>
      </div>

      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="overflow-hidden "
            transition={{
              duration: 0.5,
            }}
          >
            <div className=" w-full h-1  pt-9"></div>
            <div>
              <div className="hidden md:grid grid-cols-10 gap-x-5 text-lg border-b border-red pb-5">
                <h2 className="col-span-4 ">{rowOneTitle}</h2>
                <h2 className="col-span-4 text-red">{rowTwoTitle}</h2>
                <h2 className="col-span-2 ">{statusTitle}</h2>
              </div>
              <div className="flex flex-col space-y-5">
                {rows?.map((row, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-1 md:grid-cols-10 gap-x-5 mt-5 text-base"
                  >
                    <h2 className="col-span-4 ">{row.colOneTitle}</h2>
                    <h2 className="col-span-4 text-red">{row.colTwoTitle}</h2>
                    <div className="col-span-2 flex space-x-3 items-center">
                      <div
                        className={cx("h-4 w-4 rounded-full ", {
                          "bg-red": row.status[0] === "Not Started",
                          "bg-orange": row.status[0] === "In Trial",
                          "bg-green": row.status[0] === "Live",
                        })}
                      />
                      <h2>{row.status}</h2>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className=" w-full h-1 pt-9"></div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const InfoAccordion = ({ config, items }) => {
  const [active, setActive] = useState(0);

  return (
    <ModuleWrapper {...config}>
      <div className=" flex flex-col space-y-[50px] px-gutter">
        {items?.map((item, index) => (
          <AccordionItem
            index={index}
            active={active}
            setActive={setActive}
            {...item}
          />
        ))}
      </div>
    </ModuleWrapper>
  );
};

export default InfoAccordion;
